import {
    ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from '@/components/ui/chart'
import { useMemo } from 'react'
import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts'
import { TypographyMuted } from '@/components/ui/typography'
import getCssVariable from '../../../IntervalChart/util/getCssVariable'
import { BuyerInterval } from '../..'
import { Intervals } from '@/api/business/solicitacaoDeCompra/type'

const IntervalsLabelMapper: Record<Intervals, string> = {
    'ATRASO DE 1 À 5 DIAS': '1 à 5 dias',
    'ATRASO DE 11 À 15 DIAS': '11 à 15 dias',
    'ATRASO DE 16 À 25 DIAS': '16 à 25 dias',
    'ATRASO DE 6 À 10 DIAS': '6 à 10 dias',
    'ATRASO MAIOR QUE 25 DIAS': 'Mais que 25 dias',
    'DENTRO DO PRAZO': 'Dentro do prazo',
    INDEFINIDO: 'Indefinido',
}

const IntervalOrder: Record<Intervals, number> = {
    'DENTRO DO PRAZO': 1,
    'ATRASO DE 1 À 5 DIAS': 2,
    'ATRASO DE 6 À 10 DIAS': 3,
    'ATRASO DE 11 À 15 DIAS': 4,
    'ATRASO DE 16 À 25 DIAS': 5,
    'ATRASO MAIOR QUE 25 DIAS': 6,
    INDEFINIDO: 7,
}

type BuyersIntervalChart = {
    buyerIntervals: BuyerInterval[]
}

const BuyersIntervalChart = ({ buyerIntervals }: BuyersIntervalChart) => {
    const chartData = useMemo(() => {
        return buyerIntervals
            .filter((value) => value.interval !== 'INDEFINIDO')
            .map((value) => {
                return {
                    interval: value.interval.replace(/\s+/g, '-'),
                    total: Number(value.total),
                    fill: `var(--color-${value.interval.replace(/\s+/g, '-')})`,
                }
            })
            .sort((a, b) => {
                return (
                    IntervalOrder[a.interval.replace(/-+/g, ' ')] -
                    IntervalOrder[b.interval.replace(/-+/g, ' ')]
                )
            })
    }, [buyerIntervals])

    const chartConfig: ChartConfig = useMemo(() => {
        return buyerIntervals
            .filter((value) => value.interval !== 'INDEFINIDO')
            .reduce((acc, value) => {
                acc[value.interval.replace(/\s+/g, '-')] = {
                    label: IntervalsLabelMapper[
                        value.interval.replace(/-+/g, ' ')
                    ],
                    color: `hsl(var(${getCssVariable(value.interval)}))`,
                }

                return acc
            }, {} satisfies ChartConfig)
    }, [buyerIntervals])

    if (buyerIntervals.length === 0)
        return (
            <div className="p-4">
                <TypographyMuted>Não há dados disponíveis</TypographyMuted>
            </div>
        )

    return (
        <ChartContainer
            config={chartConfig}
            className="w-full p-4 max-h-[250px]"
        >
            <BarChart
                accessibilityLayer
                data={Object.values(chartData)}
                margin={{
                    top: 20,
                }}
            >
                <ChartTooltip content={<ChartTooltipContent />} />
                <YAxis
                    dataKey="total"
                    type="number"
                    // domain={[
                    //     'dataMin',
                    //     (dataMax: number) => dataMax / 2 + dataMax,
                    // ]}
                    hide
                />
                <XAxis
                    dataKey="interval"
                    type="category"
                    tickLine={false}
                    tickMargin={8}
                    axisLine={false}
                    tickFormatter={(value) =>
                        chartConfig[value]?.label as string
                    }
                />
                <Bar dataKey="total" radius={6}>
                    <LabelList
                        position="top"
                        className="fill-foreground"
                        fontSize={11}
                    />
                </Bar>
            </BarChart>
        </ChartContainer>
    )
}

export default BuyersIntervalChart
