import { Button } from '@/components/ui/button'
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from '@/components/ui/drawer'
import { Eye } from 'lucide-react'
import LeadTimeDashboard from '@/components/LeadTimeDashboard'
import DetailsSection from './components/DetailsSection'
import { PurchaseOrder } from '@/types/PurchaseOrder'
import InvoicesSection from './components/InvoicesSection'

type LeadTimeDrawerProps = {
    purchaseOrder: PurchaseOrder
}

const LeadTimeDrawer = ({ purchaseOrder }: LeadTimeDrawerProps) => {
    return (
        <Drawer>
            <DrawerTrigger asChild>
                <Button variant="ghost" size="icon" className="mx-auto">
                    <Eye />
                </Button>
            </DrawerTrigger>
            <DrawerContent className="h-full  max-h-[90vh]">
                <div className="flex h-full gap-4 p-6">
                    <div className="h-full space-y-1 rounded-md shrink-0 bg-accent">
                        <DrawerHeader className="pb-4">
                            <DrawerTitle>
                                Pedido de compras - #
                                {purchaseOrder.NK_PEDIDO_DE_COMPRAS}
                            </DrawerTitle>
                            <DrawerDescription>
                                Detalhamento e leadtime da pedido de compras{' '}
                                {purchaseOrder.NK_PEDIDO_DE_COMPRAS}
                            </DrawerDescription>
                        </DrawerHeader>
                        <DetailsSection purchaseOrder={purchaseOrder} />
                    </div>
                    <div className="flex flex-col flex-1 gap-4 overflow-x-hidden overflow-y-auto">
                        <LeadTimeDashboard
                            purchaseOrderId={purchaseOrder.SK_PEDIDO_DE_COMPRAS}
                        />
                        <InvoicesSection
                            purchaseOrderId={purchaseOrder.SK_PEDIDO_DE_COMPRAS}
                        />
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}
export default LeadTimeDrawer
