import { QuotationMapValuesState } from '@/store/quotationMapSlice/type'
import { Text, View } from '@react-pdf/renderer'

type ApprovalPdfCellProps = {
    data: QuotationMapValuesState
    dataKey: keyof QuotationMapValuesState
    shouldGroup?: boolean
    className?: string
    formatter?: (value: string) => string
}

const ApprovalPdfCell = ({
    data,
    shouldGroup,
    dataKey,
    className,
    formatter,
}: ApprovalPdfCellProps) => {
    const value = data[dataKey] as string

    const children = formatter ? formatter(value) : value
    const hasGroup = data.GRUPO?.length > 0

    return (
        <View style={[{ width: '100%' }]}>
            {hasGroup ? (
                <View
                    style={{
                        width: '100%',
                    }}
                >
                    {data.GRUPO.map((group) => {
                        return (
                            <ApprovalPdfCell
                                key={group.SK_RESPOSTA_FORNECEDOR}
                                data={group as QuotationMapValuesState}
                                dataKey={dataKey}
                                className={className}
                                shouldGroup={hasGroup}
                                formatter={formatter}
                            />
                        )
                    })}
                </View>
            ) : (
                <View
                    style={[
                        hasGroup || shouldGroup
                            ? {
                                  gap: 4,
                                  backgroundColor: hasGroup
                                      ? '#F5F5F5'
                                      : undefined,
                                  borderColor: '#E0E0E0',
                                  borderBottomWidth: 0.5,
                                  borderStyle: 'solid',
                              }
                            : {},
                        {
                            padding: 4,
                            alignItems: 'center',
                        },
                    ]}
                >
                    <Text
                        style={{
                            textAlign: 'right',
                            fontSize: 6,
                        }}
                    >
                        {value ? children : '-'}
                    </Text>
                </View>
            )}
        </View>
    )
}

export default ApprovalPdfCell
