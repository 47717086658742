import { Table } from '@tanstack/react-table'
import { utils, writeFile } from 'xlsx'
import { PurchaseOrder } from '@/types/PurchaseOrder'
import { PurchaseOrderTableType } from './components/PurchaseOrderTable'

const HEADERS: Partial<
    Record<
        keyof PurchaseOrder,
        {
            label: string
            order: number
        }
    >
> = {
    NK_PEDIDO_DE_COMPRAS: { label: 'Número pedido', order: 1 },
    NK_ITEM_PEDIDO_DE_COMPRAS: { label: 'Item', order: 2 },
    TIPO_PEDIDO: { label: 'Tipo', order: 3 },
    DD_STATUS: { label: 'Status', order: 4 },
    SK_EMPRESA: { label: 'Cod empresa', order: 5 },
    ABREVIATURA_EMPRESA: { label: 'Empresa', order: 6 },
    CNPJ: { label: 'CNPJ', order: 7 },
    NK_FORNECEDOR: { label: 'Cod fornecedor', order: 8 },
    DD_RAZAO_SOCIAL: { label: 'Razão social', order: 9 },
    DD_NOME_FANTASIA: { label: 'Nome fantasia', order: 10 },
    DD_UF: { label: 'UF fornecedor', order: 11 },
    NK_CENTRO_DE_CUSTO: { label: 'Cod centro de custo', order: 12 },
    CENTRO_DE_CUSTO: { label: 'Centro de custo', order: 13 },
    NK_SOLICITACAO_DE_COMPRAS: {
        label: 'Cod solicitação de compras',
        order: 14,
    },
    NK_PRODUTO: { label: 'Cod produto', order: 15 },
    DS_PRODUTO: { label: 'Produto', order: 16 },
    DD_TIPO: { label: 'Tipo produto', order: 17 },
    DD_UM: { label: 'UM', order: 18 },
    DD_NCM: { label: 'NCM', order: 19 },
    DS_GRUPO_DE_PRODUTOS: { label: 'Grupo de produtos', order: 20 },
    NK_CONTA_ORCAMENTARIA: { label: 'Cod conta orçamentária', order: 21 },
    CONTA_ORCAMENTARIA: { label: 'Conta orçamentária', order: 22 },
    NK_COMPRADOR: { label: 'Cod comprador', order: 23 },
    DD_EMAIL: { label: 'Email comprador', order: 24 },
    DS_NOME_COMPRADOR: { label: 'Nome comprador', order: 25 },
    DS_COMPRADOR: { label: 'Comprador (PROTHEUS)', order: 26 },
    DS_APROVADOR: { label: 'Aprovador', order: 27 },
    QTD_PEDIDA: { label: 'Qtd pedida', order: 28 },
    QTD_ENTREGUE: { label: 'Qtd entregue', order: 29 },
    VL_UNITARIO: { label: 'Vl unitário', order: 30 },
    VL_TOTAL: { label: 'Vl total', order: 31 },
    TIPO_SAVING: { label: 'Tipo saving', order: 32 },
    VL_SAVING: { label: 'Vl saving', order: 33 },
    PERCENTUAL_SAVING: { label: 'Percentual saving', order: 34 },
    DT_PREVISAO_ENTREGA: { label: 'Previsão entrega', order: 35 },
    DT_EMISSAO: { label: 'Emissão', order: 36 },
    DT_ESTIMATIVA_PAGAMENTO: { label: 'Estimativa pagamento', order: 37 },
    DD_URGENCIA: { label: 'Urgência', order: 38 },
    PRAZO: { label: 'Prazo', order: 39 },
    PRAZO_INTERVALO: { label: 'Prazo intervalo', order: 40 },
    DS_OBSERVACAO: { label: 'Observação', order: 41 },
} as const

export const usePurchaseOrderSpreadsheet = () => {
    const getTableRows = (
        table: Table<PurchaseOrderTableType>
    ): Partial<PurchaseOrder>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original).sort(
                (a, b) => HEADERS[a[0]]?.order - HEADERS[b[0]]?.order
            )) {
                const header = HEADERS[key]?.label

                if (header && value) {
                    obj[header] = value.toString()
                }
            }

            return obj as PurchaseOrder
        })

    const onDownload = (tableInstance: Table<PurchaseOrderTableType>) => {
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Pedidos de compras')

        writeFile(workbook, 'pedidos-de-compras.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
