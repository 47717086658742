import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { BuyerPriority } from '../..'
import BuyersIntervalChart from '../BuyersIntervalChart'
import { ScrollArea } from '@/components/ui/scroll-area'
import { TypographyP } from '@/components/ui/typography'
import getCssVariable from '../../../PriorityChart/util/getCssVariable'
import PurchaseOrderTable from '../../../PurchaseOrderAnalytics/components/PurchaseOrderTable'
import { Operators } from '@/types/Filters'
import { useDateIntervalContext } from '@/context/DateIntervalContextProvider'
import { format } from 'date-fns'

type BuyersDialogProps = {
    buyerName: string
    buyerPriorities: BuyerPriority[]
    isOpen: boolean
    onClose: () => void
}

const BuyersDialog = ({
    buyerName,
    isOpen,
    buyerPriorities,
    onClose,
}: BuyersDialogProps) => {
    const { endDate, startDate } = useDateIntervalContext()

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="flex flex-col h-full max-w-full overflow-hidden sm:rounded-none">
                <DialogHeader className="pb-4 border-b">
                    <DialogTitle>{buyerName}</DialogTitle>
                </DialogHeader>
                <div className="flex flex-1 overflow-hidden divide-x">
                    <ScrollArea className="max-w-[300px] flex-1">
                        {buyerPriorities.map((priority) => {
                            return (
                                <div
                                    key={priority.priority}
                                    className="space-y-2 divide-y"
                                >
                                    <div>
                                        <TypographyP
                                            className="font-semibold"
                                            style={{
                                                color: `hsl(var(${getCssVariable(
                                                    priority.priority
                                                )}))`,
                                            }}
                                        >
                                            {priority.priority}
                                        </TypographyP>
                                    </div>
                                    <BuyersIntervalChart
                                        buyerIntervals={priority.intervals}
                                    />
                                </div>
                            )
                        })}
                    </ScrollArea>
                    <div className="flex-1 p-4 overflow-hidden">
                        <PurchaseOrderTable
                            filters={[
                                {
                                    AND: [
                                        {
                                            field: 'DS_COMPRADOR',
                                            operator: Operators.equals,
                                            value: buyerName,
                                        },
                                        {
                                            field: 'DT_EMISSAO',
                                            operator: Operators.between,
                                            value: [
                                                format(startDate, 'yyyyMMdd'),
                                                format(endDate, 'yyyyMMdd'),
                                            ],
                                        },
                                    ],
                                },
                            ]}
                            // tableColumnFilters={[
                            //     {
                            //         id: 'DS_NOME_COMPRADOR',
                            //         value: buyerName,
                            //     },
                            // ]}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default BuyersDialog
