import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from '@/components/ui/chart'
import { useMemo } from 'react'
import { Pie, PieChart } from 'recharts'
import getCssVariable from './util/getCssVariable'
import { useStatusTotals } from '@/queries/usePurchaseRequestQuery'
import { Skeleton } from '@/components/ui/skeleton'
import { isAxiosError } from '@/api/business'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { Loader } from 'lucide-react'
import { useDateIntervalContext } from '@/context/DateIntervalContextProvider'

const StatusChartContent = () => {
    const { endDate, startDate } = useDateIntervalContext()

    const {
        data = [],
        isPending,
        isFetching,
        error,
    } = useStatusTotals({ endDate, startDate })

    const chartData = useMemo(() => {
        return data.map((value) => {
            const label = value.DD_STATUS.replace(/\s+/g, '-')

            return {
                DD_STATUS: label,
                TOTAL: value.TOTAL,
                fill: `var(--color-${label})`,
            }
        }, [])
    }, [data])

    const chartConfig: ChartConfig = useMemo(() => {
        return data.reduce((acc, value) => {
            const label =
                value.DD_STATUS.replace(/"-"+/g, ' ').charAt(0).toUpperCase() +
                value.DD_STATUS.replace(/"-"+/g, ' ').slice(1).toLowerCase()

            const key = value.DD_STATUS.replace(/\s+/g, '-')

            acc[key] = {
                label,
                color: `hsl(var(${getCssVariable(value.DD_STATUS)}))`,
            }

            return acc
        }, {} satisfies ChartConfig)
    }, [data])

    if (isPending) {
        return (
            <div className="pt-4">
                <Skeleton className="w-full h-[300px]" />
            </div>
        )
    }

    if (error) {
        let errorMessage = 'Erro ao listar status de SC'

        if (isAxiosError(error)) {
            errorMessage = error.response?.data.message || errorMessage
        }

        return (
            <div className="p-4">
                <TypographyP className="text-destructive">
                    {errorMessage}
                </TypographyP>
            </div>
        )
    }

    if (data.length === 0)
        return (
            <div className="p-4">
                <TypographyMuted>Não há dados disponíveis</TypographyMuted>
            </div>
        )

    return (
        <>
            {isFetching && (
                <div className="flex items-center justify-center gap-2 pt-2">
                    <Loader className="animate-spin text-sky-500" size={14} />
                    <TypographyMuted>Atualizando</TypographyMuted>
                </div>
            )}
            <ChartContainer
                config={chartConfig}
                className="max-w-[400px] h-full mx-auto aspect-square "
            >
                <PieChart accessibilityLayer>
                    <Pie
                        data={Object.values(chartData)}
                        dataKey="TOTAL"
                        nameKey="DD_STATUS"
                        strokeWidth={5}
                        innerRadius={40}
                        label
                        labelLine={false}
                    />
                    <ChartLegend
                        content={<ChartLegendContent nameKey="DD_STATUS" />}
                        className="-translate-y-2 flex-wrap gap-2 [&>*]:justify-center"
                    />
                    <ChartTooltip content={<ChartTooltipContent />} />
                </PieChart>
            </ChartContainer>
        </>
    )
}

const StatusChart = () => {
    return (
        <Card>
            <CardHeader className="pb-0">
                <CardTitle>Status</CardTitle>
                <CardDescription>Gráfico de status das SCs</CardDescription>
            </CardHeader>
            <CardContent className="h-[350px]">
                <StatusChartContent />
            </CardContent>
        </Card>
    )
}

export default StatusChart
