import { Skeleton } from '@/components/ui/skeleton'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import {
    TypographyH2,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { formatCpfOrCnpj } from '@/utils/stringFormatter'
import { cn } from '@/lib/utils'
import {
    QuotationStatus,
    QuotationStatusColorScheme,
} from '@/types/QuotationStatus'
import { getSelectedRoundSelector } from '@/store/quotationDetailSlice'
import { useBaseStore } from '@/store'
import { format } from 'date-fns'
import {
    Section,
    SectionContent,
    SectionHeader,
    SectionTitle,
} from '@/components/Section'
import { ScrollArea } from '@/components/ui/scroll-area'

type ItemProps = {
    label: string
    value: string
}

const Item = ({ label, value }: ItemProps) => {
    return (
        <div className="space-y-0.5">
            <TypographyMuted>{label}</TypographyMuted>
            <TypographyP className="truncate">{value}</TypographyP>
        </div>
    )
}

const QuotationDetailsSection = () => {
    const { id } = useParams()
    const selectedRound = useBaseStore(getSelectedRoundSelector)

    const { data, isPending, isError } = useQuotationByIdQuery({
        id: Number(id),
    })

    const hasApproval =
        data?.APROVACAO &&
        (data?.APROVACAO?.DD_STATUS === 'APROVADO' ||
            data?.APROVACAO?.DD_STATUS === 'RECUSADO')

    if (isPending || isError) {
        return (
            <div className="space-y-2">
                <div className="space-y-2">
                    <Skeleton className="w-[150px] h-[16px]" />
                    <Skeleton className="w-[200px] h-[26px]" />
                </div>
                <div className="space-y-2">
                    <Skeleton className="w-[150px] h-[16px]" />
                    <Skeleton className="w-[200px] h-[26px]" />
                </div>
                <div className="space-y-2">
                    <Skeleton className="w-[150px] h-[16px]" />
                    <Skeleton className="w-[200px] h-[26px]" />
                </div>
            </div>
        )
    }

    return (
        <ScrollArea className="h-full p-4 overflow-hidden">
            <div className="space-y-4">
                <div className="space-y-1.5">
                    <div className="space-y-1">
                        <TypographyH2>
                            {data?.NM_COTACAO || ''}{' '}
                            {data?.NUMERO_DA_COTACAO || ''}
                        </TypographyH2>
                        <TypographyMuted className="-mt-2 text-sm">
                            {data?.DS_COTACAO}
                        </TypographyMuted>
                    </div>
                    <div>
                        {data.STATUS.map((status) => {
                            return (
                                <span
                                    key={status}
                                    className={cn(
                                        'text-xs',
                                        QuotationStatusColorScheme[status]
                                    )}
                                >
                                    {QuotationStatus[status]}
                                </span>
                            )
                        })}
                    </div>
                </div>
                <Section>
                    <SectionHeader>
                        <SectionTitle>Rodada</SectionTitle>
                    </SectionHeader>
                    <SectionContent>
                        <Item
                            label="Rodada selecionada"
                            value={`${selectedRound?.round}ª rodada`}
                        />
                        {selectedRound?.expirationDate && (
                            <Item
                                label="Data de expiração"
                                value={format(
                                    new Date(selectedRound?.expirationDate),
                                    'PPP'
                                )}
                            />
                        )}
                    </SectionContent>
                </Section>
                <Section>
                    <SectionHeader>
                        <SectionTitle>Informações</SectionTitle>
                    </SectionHeader>
                    <SectionContent>
                        <Item
                            label="Número da cotação"
                            value={data?.NUMERO_DA_COTACAO || ''}
                        />
                        <div className="space-y-0.5">
                            <TypographyMuted>Empresas</TypographyMuted>
                            <div className="space-y-1">
                                {data.EMPRESAS.map((company) => (
                                    <div
                                        key={company.SK_EMPRESA}
                                        className="flex items-center gap-2"
                                    >
                                        <TypographyP
                                            style={{
                                                color:
                                                    company.COR_EMPRESA ||
                                                    undefined,
                                            }}
                                        >
                                            {company.ABREVIATURA_EMPRESA} -{' '}
                                            {formatCpfOrCnpj(company.CNPJ)}
                                        </TypographyP>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Item
                            label="Comprador"
                            value={data?.NOME_USUARIO || ''}
                        />
                    </SectionContent>
                </Section>
                {hasApproval && data.APROVACAO ? (
                    <Section>
                        <SectionHeader>
                            <SectionTitle>Aprovação</SectionTitle>
                        </SectionHeader>
                        <SectionContent>
                            <Item
                                label={
                                    data.APROVACAO?.DD_STATUS === 'APROVADO'
                                        ? 'Aprovado por'
                                        : 'Recusado por'
                                }
                                value={
                                    data.APROVACAO?.APROVADOR?.NOME_USUARIO ||
                                    ''
                                }
                            />
                            <Item
                                label="Email aprovador"
                                value={
                                    data.APROVACAO.APROVADOR?.EMAIL_USUARIO ||
                                    ''
                                }
                            />
                            <Item
                                label="Observação"
                                value={
                                    data.APROVACAO.APROVADOR
                                        ?.DS_OBSERVACAO_APROVACAO || '-'
                                }
                            />
                        </SectionContent>
                    </Section>
                ) : null}
            </div>
        </ScrollArea>
    )
}

export default QuotationDetailsSection
