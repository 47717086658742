import { Styles, StyleSheet, Text, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { ReactNode, useMemo } from 'react'

type CommonTableColumnAttributes<T> = {
    id: string
    label: string
    style?: Style
    footerStyle?: Style
    size?: number
    cell?: (row: T, value?: any) => ReactNode
    footer?: (rows: Partial<T>[]) => ReactNode
    computedWidth?: number
}

export type TableColumn<T extends object> =
    | (CommonTableColumnAttributes<T> & {
          accessor?: keyof T
          columns?: undefined
      })
    | (CommonTableColumnAttributes<T> & {
          accessor?: undefined
          columns?: TableColumn<T>[]
      })

export type TableRowData<T extends object> = Partial<T>

interface TableProps<T extends object> {
    columns: TableColumn<T>[]
    data: TableRowData<T>[]
}

interface HeaderCellMeta {
    label: string
    width: number
    isPlaceholder: boolean
}

const styles = StyleSheet.create({
    tableContainer: {
        width: '100%',
        borderWidth: 0.5,
        borderColor: '#000',
    },
    headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        borderColor: '#000',
        backgroundColor: '#eee',
    },
    headerCell: {
        padding: 4,
        borderRightWidth: 0.5,
        borderRightColor: '#000',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 8,
    },
    dataRow: {
        flexDirection: 'row',
        borderBottomWidth: 0.5,
        borderColor: '#ccc',
    },
    dataCell: {
        padding: 4,
        borderRightWidth: 0.5,
        borderRightColor: '#ccc',
        textAlign: 'center',
        fontSize: 8,
    },
    footerRow: {
        flexDirection: 'row',
        borderTopWidth: 0.5,
        borderColor: '#000',
        backgroundColor: '#eee',
    },
    footerCell: {
        padding: 4,
        borderRightWidth: 0.5,
        borderRightColor: '#000',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 8,
    },
})

function computeWidth<T extends object>(col: TableColumn<T>): number {
    if (col.columns && col.columns.length > 0) {
        col.columns = col.columns.map((c) => {
            const w = computeWidth(c)
            return { ...c, computedWidth: w }
        })
        const total = col.columns.reduce(
            (acc, c) => acc + (c.computedWidth || 70),
            0
        )
        col.computedWidth = total
        return total
    } else {
        col.computedWidth = col.size ?? 70
        return col.computedWidth
    }
}

function prepareColumns<T extends object>(
    cols: TableColumn<T>[]
): TableColumn<T>[] {
    return cols.map((col) => {
        const w = computeWidth(col)
        return { ...col, computedWidth: w }
    })
}

function getMaxDepth<T extends object>(
    cols: TableColumn<T>[],
    level = 1
): number {
    let max = level
    for (const col of cols) {
        if (col.columns && col.columns.length > 0) {
            max = Math.max(max, getMaxDepth(col.columns, level + 1))
        }
    }
    return max
}

function buildHeaderMatrix<T extends object>(
    columns: TableColumn<T>[],
    maxDepth: number
): HeaderCellMeta[][] {
    const matrix: HeaderCellMeta[][] = Array.from(
        { length: maxDepth },
        () => []
    )

    function fillCols(cols: TableColumn<T>[], currentLevel: number) {
        for (const col of cols) {
            const colWidth = col.computedWidth || 70
            if (col.columns && col.columns.length > 0) {
                matrix[currentLevel].push({
                    label: col.label,
                    width: colWidth,
                    isPlaceholder: false,
                })
                fillCols(col.columns, currentLevel + 1)
            } else {
                matrix[currentLevel].push({
                    label: col.label,
                    width: colWidth,
                    isPlaceholder: false,
                })
                for (let i = currentLevel + 1; i < maxDepth; i++) {
                    matrix[i].push({
                        label: '',
                        width: colWidth,
                        isPlaceholder: true,
                    })
                }
            }
        }
    }

    fillCols(columns, 0)
    return matrix
}

function flattenColumns<T extends object>(
    cols: TableColumn<T>[]
): TableColumn<T>[] {
    let flat: TableColumn<T>[] = []
    for (const col of cols) {
        if (col.columns && col.columns.length > 0) {
            flat = flat.concat(flattenColumns(col.columns))
        } else {
            flat.push(col)
        }
    }
    return flat
}

function HeaderRows<T extends object>({
    columns,
}: {
    columns: TableColumn<T>[]
}) {
    const depth = getMaxDepth(columns)
    const headerMatrix = buildHeaderMatrix(columns, depth)
    return (
        <>
            {headerMatrix.map((row, rowIndex) => (
                <View style={styles.headerRow} key={`header-row-${rowIndex}`}>
                    {row.map((cell, colIndex) => (
                        <Text
                            key={`hdr-col-${colIndex}`}
                            style={[styles.headerCell, { width: cell.width }]}
                        >
                            {cell.isPlaceholder ? '' : cell.label}
                        </Text>
                    ))}
                </View>
            ))}
        </>
    )
}

function DataRows<T extends object>({
    columns,
    data,
}: {
    columns: TableColumn<T>[]
    data: TableRowData<T>[]
}) {
    return (
        <>
            {data.map((rowData, rowIndex) => (
                <View style={styles.dataRow} key={`data-row-${rowIndex}`}>
                    {columns.map((col) => {
                        const width = col.computedWidth || 70
                        const cellStyles = [
                            styles.dataCell,
                            { width },
                            col.style as Styles,
                        ]
                        if (col.cell) {
                            const val = col.accessor
                                ? rowData[col.accessor]
                                : undefined
                            return (
                                <View key={col.id} style={cellStyles}>
                                    {col.cell(rowData as T, val)}
                                </View>
                            )
                        }
                        let content = ''
                        if (col.accessor) {
                            const val = rowData[col.accessor]
                            if (val !== undefined) {
                                content = String(val)
                            }
                        }
                        return (
                            <Text key={col.id} style={cellStyles}>
                                {content}
                            </Text>
                        )
                    })}
                </View>
            ))}
        </>
    )
}

function FooterRow<T extends object>({
    columns,
    data,
}: {
    columns: TableColumn<T>[]
    data: TableRowData<T>[]
}) {
    const hasFooter = columns.some((col) => col.footer)
    if (!hasFooter) return null
    return (
        <View style={styles.footerRow}>
            {columns.map((col) => {
                const width = col.computedWidth || 70
                const footerStyles = [
                    styles.footerCell,
                    { width },
                    col.style as Styles,
                    col.footerStyle as Styles,
                ]
                let content: ReactNode = ''
                if (col.footer) {
                    content = col.footer(data)
                }
                return (
                    <View key={col.id} style={footerStyles}>
                        {content}
                    </View>
                )
            })}
        </View>
    )
}

export function Table<T extends object>({ columns, data }: TableProps<T>) {
    const preparedColumns = useMemo(() => prepareColumns(columns), [columns])
    const flat = useMemo(
        () => flattenColumns(preparedColumns),
        [preparedColumns]
    )

    return (
        <View style={styles.tableContainer}>
            <HeaderRows columns={preparedColumns} />
            <DataRows columns={flat} data={data} />
            <FooterRow columns={flat} data={data} />
        </View>
    )
}
