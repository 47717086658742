import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { Copy, CopyCheck } from 'lucide-react'
import { useState } from 'react'

type FieldProps = {
    value: string
    label: string
    enableCopyValue?: boolean
}

const Field = ({ label, value, enableCopyValue }: FieldProps) => {
    const [hasCopied, setHasCopied] = useState(false)

    const onCopy = () => {
        navigator.clipboard.writeText(value)
        setHasCopied(true)
        setTimeout(() => {
            setHasCopied(false)
        }, 5000)
    }

    return (
        <div className="-space-y-0.5">
            <TypographyMuted>{label}</TypographyMuted>
            <div className="flex items-center gap-2">
                {enableCopyValue && !!value && (
                    <button
                        className="text-sm text-accent-foreground"
                        onClick={onCopy}
                    >
                        {hasCopied ? (
                            <CopyCheck className="text-emerald-600" size={14} />
                        ) : (
                            <Copy size={14} />
                        )}
                    </button>
                )}
                <TypographyP className="text-accent-foreground">
                    {value || '-'}
                </TypographyP>
            </div>
        </div>
    )
}

export default Field
