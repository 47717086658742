import PurchaseOrderTable from './components/PurchaseOrderTable'

const PurchaseOrderAnalytics = () => {
    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <PurchaseOrderTable />
        </div>
    )
}

export default PurchaseOrderAnalytics
