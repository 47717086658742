import PurchaseRequestTable from './components/PurchaseRequestTable'

const PurchaseRequestAnalytics = () => {
    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <PurchaseRequestTable />
        </div>
    )
}

export default PurchaseRequestAnalytics
