import Container from '@/components/Container'
import {
    TypographyH1,
    TypographyMuted,
    TypographyP,
} from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { Link, useParams } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import { Separator } from '@/components/ui/separator'
import SummarySection from './componentes/SummarySection'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import ApprovalDialog from './componentes/ApprovalDialog'
import ApproveQuotationMapDialog from './componentes/ApproveQuotationMapDialog'
import DeclineQuotationMapDialog from './componentes/DeclineQuotationMapDialog'
import useDisclosure from '@/hooks/useDisclosure'
import ApprovalPdf from './componentes/ApprovalPdf'

const QuotationApprovalHeader = () => {
    const { id } = useParams()

    return (
        <>
            <div className="flex items-center justify-between px-4 py-2">
                <div>
                    <Link
                        to={`/cotacoes/${id}/?tab=map`}
                        className="flex items-center gap-2 hover:underline"
                    >
                        <ArrowLeft size={14} />
                        <TypographyP>Voltar para o mapa</TypographyP>
                    </Link>
                </div>
                <ApprovalDialog />
            </div>
            <Separator />
        </>
    )
}

const QuotationApproval = () => {
    const { id } = useParams()
    const { data: quotationDetail } = useQuotationByIdQuery({ id: Number(id) })

    const status = quotationDetail?.APROVACAO?.DD_STATUS
    const observation =
        quotationDetail?.APROVACAO?.APROVADOR?.DS_OBSERVACAO_APROVACAO

    const {
        isOpen: isApproveQuotationMapDialogOpen,
        onOpen: onApproveQuotationMapDialogOpen,
        onClose: onApproveQuotationMapDialogClose,
    } = useDisclosure()
    const {
        isOpen: isDeclineQuotationMapDialogOpen,
        onOpen: onDeclineQuotationMapDialogOpen,
        onClose: onDeclineQuotationMapDialogClose,
    } = useDisclosure()

    if (!status)
        return (
            <div>
                <QuotationApprovalHeader />
                <div className="p-4">
                    <TypographyMuted>
                        Mapa não está em aprovação
                    </TypographyMuted>
                </div>
            </div>
        )

    return (
        <>
            <div className="flex h-full divide-x">
                <Container className="md:p-0">
                    <QuotationApprovalHeader />
                    <div className="flex flex-col flex-1 p-4 space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <TypographyH1>Aprovação</TypographyH1>
                                {status === 'EM_APROVACAO' && (
                                    <TypographyP className="text-base text-amber-600">
                                        Aguardando aprovação
                                    </TypographyP>
                                )}
                                {status === 'RECUSADO' && (
                                    <TypographyP className="text-base text-destructive">
                                        Mapa recusado por aprovador
                                    </TypographyP>
                                )}
                                {status === 'APROVADO' && (
                                    <TypographyP className="text-base text-green-600">
                                        Mapa aprovado
                                    </TypographyP>
                                )}
                                {observation && (
                                    <TypographyP className="text-muted-foreground">
                                        Observação aprovador:{' '}
                                        <span className="font-semibold">
                                            {' '}
                                            {observation}
                                        </span>
                                    </TypographyP>
                                )}
                            </div>
                            <div className="space-x-2">
                                <Button
                                    className="text-green-900 bg-green-400 hover:bg-green-500/80"
                                    onClick={onApproveQuotationMapDialogOpen}
                                    disabled={status !== 'EM_APROVACAO'}
                                >
                                    Aprovar
                                </Button>
                                <Button
                                    variant="destructive"
                                    onClick={onDeclineQuotationMapDialogOpen}
                                    disabled={status !== 'EM_APROVACAO'}
                                >
                                    Declinar
                                </Button>
                                {/* <ApprovalPdfDialog /> */}
                            </div>
                        </div>
                        <div className="flex-1">
                            <ApprovalPdf />
                            {/* <ApprovalTable /> */}
                        </div>
                    </div>
                </Container>
                <SummarySection />
            </div>
            <ApproveQuotationMapDialog
                isOpen={isApproveQuotationMapDialogOpen}
                onClose={onApproveQuotationMapDialogClose}
            />
            <DeclineQuotationMapDialog
                isOpen={isDeclineQuotationMapDialogOpen}
                onClose={onDeclineQuotationMapDialogClose}
            />
        </>
    )
}

export default QuotationApproval
