import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useInvoiceListQuery } from '@/queries/useInvoiceQuery'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import DadosMestreHeader from '@/pages/DadosMestre/components/DadosMestreHeader'
import DadosMestreHeaderActions from '@/pages/DadosMestre/components/DadosMestreHeaderActions'
import { useTable } from '@/hooks/useTable'
import { Filter, Operators } from '@/types/Filters'
import { isAxiosError } from '@/api/business'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { Invoice } from '@/api/business/notasFiscais/type'
import { format, isValid } from 'date-fns'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import LeadTimeDrawer from './components/LeadTimeDrawer'

export type InvoiceTable = TableData<Invoice>

const columnHelper = createColumnHelper<Invoice>()

const Invoices = () => {
    const {
        table,
        selectedRows,
        pageIndex,
        pageSize,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
        onClickMultiDelete,
        setTable,
    } = useTable<InvoiceTable>()

    const [fieldFilters, setFieldFilters] = useState<Filter<Invoice>[]>([])

    const { data, isFetching, isPending, isError, error, refetch } =
        useInvoiceListQuery({
            currentPage: pageIndex,
            perPage: pageSize,
            filters: fieldFilters,
            enabled: true,
        })

    const memoData = useMemo(() => (data ? data.DADOS : []), [data])
    const memoColumns = useMemo(
        () => [
            columnHelper.display({
                id: 'ACOES',
                header: 'Ações',
                cell: ({ row }) => {
                    const invoice = row.original

                    return <LeadTimeDrawer invoice={invoice} />
                },
                size: 100,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod empresa',
                size: 130,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 150,
            }),
            columnHelper.accessor('DD_DOCUMENTO_NF', {
                id: 'DD_DOCUMENTO_NF',
                header: 'Documento',
                size: 130,
            }),
            columnHelper.accessor('DD_ITEM_NF', {
                id: 'DD_ITEM_NF',
                header: 'Item',
                size: 100,
            }),
            columnHelper.accessor('DD_SERIE_NF', {
                id: 'DD_SERIE_NF',
                header: 'Série',
                size: 100,
            }),
            columnHelper.accessor('DD_TES', {
                id: 'DD_TES',
                header: 'TES',
                size: 100,
            }),
            columnHelper.accessor('DD_CODIGO_FISCAL', {
                id: 'DD_CODIGO_FISCAL',
                header: 'Código fiscal',
                size: 130,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod produto',
                size: 130,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 500,
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),
            columnHelper.accessor('VL_UNITARIO', {
                id: 'VL_UNITARIO',
                header: 'Vlr unitário',
                size: 120,
                meta: {
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('VL_TOTAL', {
                id: 'VL_TOTAL',
                header: 'Vlr total',
                size: 150,
                meta: {
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('IPI', {
                id: 'IPI',
                header: 'IPI',
                size: 120,
                meta: {
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('ST', {
                id: 'ST',
                header: 'ST',
                size: 120,
                meta: {
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('ICMS', {
                id: 'ICMS',
                header: 'ICMS',
                size: 120,
                meta: {
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('DS_OBSERVACAO', {
                id: 'DS_OBSERVACAO',
                header: 'Observação',
                size: 400,
            }),
            columnHelper.accessor('NK_PEDIDO_DE_COMPRAS', {
                id: 'NK_PEDIDO_DE_COMPRAS',
                header: 'Pedido de compras',
                size: 170,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'Solic. de compras',
                size: 170,
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                id: 'NK_CENTRO_DE_CUSTO',
                header: 'Número CC',
                size: 140,
            }),
            columnHelper.accessor('CENTRO_DE_CUSTO', {
                id: 'CENTRO_DE_CUSTO',
                header: 'Centro de custo',
                size: 300,
            }),
            columnHelper.accessor('NK_FORNECEDOR', {
                id: 'NK_FORNECEDOR',
                header: 'Cod Fornecedor',
                size: 150,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Rz social',
                size: 400,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nm fantasia',
                size: 400,
            }),
            columnHelper.accessor('DD_LOJA', {
                id: 'DD_LOJA',
                header: 'Loja',
                size: 100,
            }),
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 200,
                meta: {
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? formatCpfOrCnpj(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('DT_EMISSAO', {
                id: 'DT_EMISSAO',
                header: 'Dt emissão',
                size: 130,
                meta: {
                    cell: {
                        formatterFn: ({ value }) =>
                            value
                                ? format(getDateFromId(value), 'dd/MM/yyyy')
                                : '-',
                    },
                },
            }),
            columnHelper.accessor('DT_DIGITACAO', {
                id: 'DT_DIGITACAO',
                header: 'Dt digitação',
                size: 130,
                meta: {
                    cell: {
                        formatterFn: ({ value }) =>
                            value
                                ? format(getDateFromId(value), 'dd/MM/yyyy')
                                : '-',
                    },
                },
            }),
        ],
        []
    )

    return (
        <div className="w-full h-full p-4 overflow-hidden">
            <Table<InvoiceTable>
                data={memoData}
                columns={memoColumns}
                getRowId={(row) => row.SK_NF.toString()}
                isLoading={isPending}
                isFetching={isFetching}
                isError={isError}
                errorMessage={
                    isError && isAxiosError(error)
                        ? error.response?.data.message
                        : 'Erro ao carregar pedidos de compras'
                }
                getTableInstance={(table) => setTable(table)}
                tableState={{
                    columnFilters,
                    pagination: {
                        pageSize,
                        pageIndex,
                    },
                }}
                pagination={{
                    pageSize,
                    totalPages: data?.TOTAL_DE_PAGINAS || 0,
                    totalItems: data?.TOTAL || 0,
                }}
                onPaginationChange={onPaginationChange}
                onColumnFiltersChange={(filters) => {
                    if (filters.length > 0) {
                        const filter: Filter<Invoice>[] = [
                            {
                                AND: filters.map((filter) => {
                                    if (filter.id.includes('DT_')) {
                                        const dateString = (
                                            filter.value as string
                                        )
                                            .split('/')
                                            .map(Number)

                                        const date = new Date(
                                            dateString[2],
                                            dateString[1] - 1,
                                            dateString[0]
                                        )

                                        const formattedDate = isValid(date)
                                            ? getIdFromDate(date).join('')
                                            : (filter.value as string)

                                        return {
                                            field: filter.id as keyof Invoice,
                                            value: formattedDate,
                                            operator: Operators.equals,
                                        }
                                    }

                                    switch (filter.id) {
                                        case 'DD_RATEIO':
                                            return {
                                                field: filter.id as keyof Invoice,
                                                value:
                                                    (
                                                        filter.value as string
                                                    ).toLowerCase() === 'sim'
                                                        ? 1
                                                        : 0,
                                                operator: Operators.like,
                                            }
                                        default:
                                            return {
                                                field: filter.id as keyof Invoice,
                                                value: filter.value as string,
                                                operator: Operators.like,
                                            }
                                    }
                                }),
                            },
                        ]

                        setFieldFilters(filter)
                    } else {
                        setFieldFilters([])
                    }
                    onColumnFiltersChange(filters)
                }}
                tableHeader={
                    <DadosMestreHeader
                        title="Notas fiscais de entrada"
                        onDelete={onClickMultiDelete}
                        selectedRows={
                            table
                                ?.getFilteredRowModel()
                                .flatRows.filter((row) => selectedRows[row.id])
                                .length
                        }
                    />
                }
                tableActions={
                    <DadosMestreHeaderActions
                        onRefech={refetch}
                        tableId="DADOS_MESTRE_SOLICITACAO_DE_COMPRAS"
                    />
                }
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
        </div>
    )
}

export default Invoices
