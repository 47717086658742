import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useBaseStore } from '@/store'
import { quotationMapStateSelector } from '@/store/quotationMapSlice'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import { Company } from '@/types/Companies'
import { QuotationDetails } from '@/types/Quotation'
import {
    Document,
    Page,
    PDFViewer,
    StyleSheet,
    Text,
    View,
} from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { ReactNode, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { QuotationMapValues } from '@/api/business/cotacoes/type'
import { Table, TableColumn } from '../Table'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import ApprovalPdfCell from './components/ApprovalPdfCell'
import { format, isValid } from 'date-fns'
import { ApprovalStatus } from '@/types/Approval'
import { useQuotationSuppliersTotals } from '@/hooks/useQuotationSuppliersTotals'

const ApprovalComponent: Record<ApprovalStatus, ReactNode> = {
    APROVADO: (
        <View
            style={{
                alignItems: 'center',
                gap: 2,
            }}
        >
            <View
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: '#22c55e',
                }}
            />
            <Text
                style={{
                    color: '#22c55e',
                    fontSize: 8,
                }}
            >
                Aprovado
            </Text>
        </View>
    ),
    RECUSADO: (
        <View
            style={{
                alignItems: 'center',
                gap: 2,
            }}
        >
            <View
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: '#f83030',
                }}
            />
            <Text
                style={{
                    color: '#f83030',
                    fontSize: 8,
                }}
            >
                Recusado
            </Text>
        </View>
    ),
    PENDENTE: (
        <View
            style={{
                alignItems: 'center',
                gap: 2,
            }}
        >
            <View
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: '#fbc616',
                }}
            />
            <Text
                style={{
                    color: '#fbc616',
                    fontSize: 8,
                }}
            >
                Pendente
            </Text>
        </View>
    ),
    EM_APROVACAO: (
        <View
            style={{
                alignItems: 'center',
                gap: 2,
            }}
        >
            <View
                style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: '#fbc616',
                }}
            />
            <Text
                style={{
                    color: '#fbc616',
                }}
            >
                Em aprovação
            </Text>
        </View>
    ),
}

const styles = StyleSheet.create({
    bgAccent: {
        backgroundColor: '#e1e1e1',
        color: '#2f2f2f',
    },
    textForeground: {
        color: '#323232',
    },
    textAccentForeground: {
        color: '#2f2f2f',
    },
    flex1: {
        flexDirection: 'row',
        flexGrow: 1,
    },
    infoLabel: {
        borderWidth: 0.5,
        borderColor: '#828282',
        backgroundColor: '#e1e1e1',
        color: '#2f2f2f',
        padding: 4,
        borderRightWidth: 0,
    },
    infoValue: {
        borderWidth: 0.5,
        borderColor: '#828282',
        padding: 4,
        flexGrow: 1,
    },
    totalsLine: {
        padding: 8,
        borderWidth: 0.5,
        borderColor: '#828282',
        alignItems: 'center',
    },
})

type RowProps = {
    children: ReactNode
    style?: Style
}

const Row = ({ children, style }: RowProps) => {
    return (
        <View
            style={[
                {
                    gap: 2,
                    flexDirection: 'row',
                    width: '100%',
                },
                style as Style,
            ]}
        >
            {children}
        </View>
    )
}

type HeaderProps = {
    company: Company
    quotationDetails: QuotationDetails
}

const Header = ({ company, quotationDetails }: HeaderProps) => {
    return (
        <View
            key={company.SK_EMPRESA}
            style={{
                padding: 10,
                gap: 8,
            }}
        >
            <View
                style={[
                    styles.bgAccent,
                    {
                        padding: 8,
                        borderColor: '#828282',
                        borderWidth: 0.5,
                    },
                ]}
            >
                <Text>DADOS DA EMPRESA SOLICITANTE</Text>
            </View>
            <Row>
                <View style={[styles.flex1]}>
                    <View style={[styles.infoLabel]}>
                        <Text>UNIDADE: </Text>
                    </View>
                    <View style={[styles.infoValue]}>
                        <Text>{company.ABREVIATURA_EMPRESA}</Text>
                    </View>
                </View>
                <View style={[styles.flex1]}>
                    <View style={[styles.infoLabel]}>
                        <Text>CNPJ: </Text>
                    </View>
                    <View style={[styles.infoValue]}>
                        <Text>{company.CNPJ}</Text>
                    </View>
                </View>
            </Row>
            <Row>
                <View style={[styles.flex1]}>
                    <View style={[styles.infoLabel]}>
                        <Text>COMPRADOR: </Text>
                    </View>
                    <View style={[styles.infoValue]}>
                        <Text>{quotationDetails?.NOME_USUARIO}</Text>
                    </View>
                </View>
                <View style={[styles.flex1]}>
                    <View style={[styles.infoLabel]}>
                        <Text>EMAIL: </Text>
                    </View>
                    <View style={[styles.infoValue]}>
                        <Text>{quotationDetails?.EMAIL_USUARIO}</Text>
                    </View>
                </View>
            </Row>
        </View>
    )
}

const ApprovalPdf = () => {
    const { id } = useParams()

    const { data } = useQuotationByIdQuery({ id: Number(id) })

    const { quotationMap } = useBaseStore(quotationMapStateSelector)

    const { suppliers: suppliersTotals } = useQuotationSuppliersTotals()

    const worstPrice = Math.max(
        ...suppliersTotals
            .filter((value) => value.totalInitial !== 0)
            .map((supplier) => supplier.totalInitial)
    )
    const bestPrice = Math.min(
        ...suppliersTotals
            .filter((value) => value.totalFinal !== 0)
            .map((supplier) => supplier.totalFinal)
    )

    const suppliers = useMemo(
        () => Object.values(quotationMap?.FORNECEDORES.entities || []),
        [quotationMap.FORNECEDORES]
    )

    const products = useMemo(
        () => Object.values(quotationMap?.PRODUTOS.entities || []),
        [quotationMap.PRODUTOS]
    )

    const companies = useMemo(
        () => quotationMap?.EMPRESAS,
        [quotationMap.EMPRESAS]
    )

    const values = quotationMap?.VALORES || []

    const getValue = useCallback(
        (
            supplier: QuotationMapSupplierValuesState,
            quotationRoundId: number,
            quotationProductId: number
        ) => {
            const foundProduct = quotationMap?.VALORES.find(
                (value) =>
                    supplier.FORNECEDOR_RODADAS.includes(
                        value.SK_FORNECEDOR_X_RODADA
                    ) &&
                    quotationRoundId === value.SK_COTACAO_X_RODADA &&
                    value.SK_COTACAO_X_PRODUTO === quotationProductId
            )

            return foundProduct
        },
        [quotationMap]
    )

    return (
        <PDFViewer className="w-full h-full">
            <Document
                title="Mapa de cotação"
                subject={`Valores da cotação ${data?.NUMERO_DA_COTACAO}`}
                author={data?.NOME_USUARIO}
            >
                <Page
                    id={data?.NUMERO_DA_COTACAO}
                    size={suppliers.length > 2 ? 'SRA1' : 'SRA2'}
                    orientation="landscape"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        fontSize: 9,
                    }}
                >
                    <View
                        style={[
                            styles.bgAccent,
                            {
                                textAlign: 'center',
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderColor: '#828282',
                                borderWidth: 0.5,
                            },
                        ]}
                    >
                        <Text
                            style={[
                                styles.textAccentForeground,
                                {
                                    fontWeight: 'bold',
                                },
                            ]}
                        >
                            MAPA DE COMPRAS
                        </Text>
                    </View>
                    {companies.map((company) => {
                        const tableData = useMemo(() => {
                            return products.map((product) => {
                                return {
                                    ...product,
                                    ...values.find(
                                        (value) =>
                                            value.SK_COTACAO_X_PRODUTO ===
                                            product.SK_COTACAO_X_PRODUTO
                                    )!,
                                }
                            })
                        }, [products, quotationMap, values])

                        const columns: TableColumn<(typeof tableData)[0]>[] =
                            useMemo(
                                () => [
                                    {
                                        id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                                        label: 'Item',
                                        accessor:
                                            'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                                        size: 100,
                                    },
                                    {
                                        id: 'NK_SOLICITACAO_DE_COMPRAS',
                                        label: 'SC',
                                        accessor: 'NK_SOLICITACAO_DE_COMPRAS',
                                        size: 130,
                                    },
                                    {
                                        id: 'DS_PRODUTO',
                                        label: 'Produto',
                                        accessor: 'DS_PRODUTO',
                                        size: 500,
                                    },
                                    {
                                        id: 'DD_UM',
                                        label: 'UM',
                                        accessor: 'DD_UM',
                                        size: 130,
                                    },
                                    {
                                        id: 'QTD_SOLICITADA',
                                        label: 'Qtd Solic.',
                                        accessor: 'QTD_SOLICITADA',
                                        size: 100,
                                    },
                                    {
                                        id: 'ULTIMO_PRECO_IPCA',
                                        label: 'IPCA ($)',
                                        accessor: 'ULTIMO_PRECO_IPCA',
                                        size: 300,
                                        cell: (val) => {
                                            return (
                                                <Text
                                                    style={{
                                                        justifyContent:
                                                            'flex-start',
                                                    }}
                                                >
                                                    {currencyFormat(
                                                        Number(val) || 0
                                                    )}
                                                </Text>
                                            )
                                        },
                                    },
                                    {
                                        id: 'fornecedores',
                                        label: 'Fornecedores',
                                        columns: suppliers.map((supplier) => {
                                            const column: TableColumn<QuotationMapValues> =
                                                {
                                                    id: supplier.SK_FORNECEDOR,
                                                    label: supplier.DD_NOME_FANTASIA,
                                                    columns: [],
                                                }

                                            const supplierFirstRound = Math.min(
                                                ...supplier.FORNECEDOR_COTACAO_RODADAS
                                            )
                                            const supplierLastRound = Math.max(
                                                ...supplier.FORNECEDOR_COTACAO_RODADAS
                                            )

                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-aprovacao`,
                                                label: 'Aprovação',
                                                size: 200,
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    const status =
                                                        value.APROVACAO
                                                            ?.DD_STATUS

                                                    return (
                                                        <Text>
                                                            {status
                                                                ? ApprovalComponent[
                                                                      status
                                                                  ]
                                                                : '-'}
                                                        </Text>
                                                    )
                                                },
                                                footerStyle: {
                                                    padding: 0,
                                                },
                                                footer: () => {
                                                    return (
                                                        <View
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            <View
                                                                style={{
                                                                    width: '100%',
                                                                    borderBottomWidth: 1,
                                                                    borderColor:
                                                                        '#CCC',
                                                                    padding: 4,
                                                                    height: 20,
                                                                }}
                                                            >
                                                                <Text>
                                                                    Frete
                                                                    inicial:
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    padding: 4,
                                                                }}
                                                            >
                                                                <Text>
                                                                    Cond.
                                                                    pagamento
                                                                    inicial:
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-qtd-disponivel`,
                                                label: 'Qtd. disponível',
                                                size: 300,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="QTD_DISPONIVEL"
                                                        />
                                                    )
                                                },
                                                footerStyle: {
                                                    backgroundColor: '#FFF',
                                                },
                                                footer: () => {
                                                    const value = values.filter(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    supplierFirstRound ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                    )

                                                    const firstValue = value[0]

                                                    if (!firstValue) return null

                                                    return (
                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'column',
                                                                backgroundColor:
                                                                    '#FFF',
                                                            }}
                                                        >
                                                            <View
                                                                style={{
                                                                    width: '100%',
                                                                    borderBottomWidth: 1,
                                                                    borderColor:
                                                                        '#CCC',
                                                                    padding: 4,
                                                                    height: 20,
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontSize: 8,
                                                                    }}
                                                                >
                                                                    {
                                                                        firstValue.FRETE
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    padding: 4,
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontSize: 8,
                                                                    }}
                                                                >
                                                                    {
                                                                        firstValue.DS_CONDICAO_DE_PAGAMENTO
                                                                    }
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-valor-inicial`,
                                                label: 'Valor unit. inicial',
                                                size: 300,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierFirstRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="PRECO"
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                                footerStyle: {
                                                    padding: 0,
                                                },
                                                footer: () => {
                                                    return (
                                                        <View
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            <View
                                                                style={{
                                                                    width: '100%',
                                                                    borderBottomWidth: 1,
                                                                    borderColor:
                                                                        '#CCC',
                                                                    padding: 4,
                                                                    height: 20,
                                                                }}
                                                            >
                                                                <Text>
                                                                    Frete final:
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    padding: 4,
                                                                }}
                                                            >
                                                                <Text>
                                                                    Cond.
                                                                    pagamento
                                                                    final:
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-valor-final`,
                                                label: 'Valor unit. negociado',
                                                size: 200,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="PRECO"
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                                footerStyle: {
                                                    backgroundColor: '#FFF',
                                                },
                                                footer: () => {
                                                    const value = values.filter(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    supplierLastRound ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                    )

                                                    const firstValue = value[0]

                                                    if (!firstValue) return null

                                                    return (
                                                        <View
                                                            style={{
                                                                flexDirection:
                                                                    'column',
                                                                backgroundColor:
                                                                    '#FFF',
                                                            }}
                                                        >
                                                            <View
                                                                style={{
                                                                    width: '100%',
                                                                    borderBottomWidth: 1,
                                                                    borderColor:
                                                                        '#CCC',
                                                                    padding: 4,
                                                                    height: 20,
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontSize: 8,
                                                                    }}
                                                                >
                                                                    {
                                                                        firstValue.FRETE
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    padding: 4,
                                                                }}
                                                            >
                                                                <Text
                                                                    style={{
                                                                        fontSize: 8,
                                                                    }}
                                                                >
                                                                    {
                                                                        firstValue.DS_CONDICAO_DE_PAGAMENTO
                                                                    }
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-pis`,
                                                label: 'PIS',
                                                size: 200,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="PIS"
                                                            formatter={
                                                                percentageFormat
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-icms`,
                                                label: 'ICMS',
                                                size: 200,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="ICMS"
                                                            formatter={
                                                                percentageFormat
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-cofins`,
                                                label: 'COFINS',
                                                size: 200,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="COFINS"
                                                            formatter={
                                                                percentageFormat
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-ipi`,
                                                label: 'IPI',
                                                size: 200,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="IPI"
                                                            formatter={
                                                                percentageFormat
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-valor-total-final`,
                                                label: 'Valor total inicial',
                                                size: 300,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="PRECO_TOTAL"
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-total-valor-final`,
                                                label: 'Valor total negociado',
                                                size: 300,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="PRECO_TOTAL"
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                            column.columns?.push({
                                                id: `${supplier.SK_FORNECEDOR}-previsao-entrega`,
                                                label: 'Previsão de entrega',
                                                size: 300,
                                                style: {
                                                    padding: 0,
                                                },
                                                cell: (row) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row

                                                    const value = getValue(
                                                        supplier,
                                                        supplierLastRound,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <ApprovalPdfCell
                                                            data={value}
                                                            dataKey="PREVISAO_DE_ENTREGA"
                                                            formatter={(
                                                                date
                                                            ) => {
                                                                return date &&
                                                                    isValid(
                                                                        new Date(
                                                                            date
                                                                        )
                                                                    )
                                                                    ? format(
                                                                          new Date(
                                                                              date
                                                                          ),
                                                                          'dd/MM/yyyy'
                                                                      )
                                                                    : '-'
                                                            }}
                                                        />
                                                    )
                                                },
                                            })

                                            return column
                                        }),
                                    },
                                ],
                                []
                            )

                        return (
                            data && (
                                <View
                                    key={company.SK_EMPRESA}
                                    style={{
                                        paddingHorizontal: 8,
                                    }}
                                >
                                    <Header
                                        company={company}
                                        quotationDetails={data}
                                    />
                                    <Table data={tableData} columns={columns} />
                                </View>
                            )
                        )
                    })}
                    <View
                        style={{
                            padding: 10,
                            gap: 4,
                        }}
                    >
                        <View
                            style={[
                                styles.bgAccent,
                                {
                                    textAlign: 'center',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderColor: '#828282',
                                    borderWidth: 0.5,
                                },
                            ]}
                        >
                            <Text>ANÁLISE DA COTAÇÃO</Text>
                        </View>
                        <View style={{ flexDirection: 'row', gap: 80 }}>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    gap: 4,
                                }}
                            >
                                <View
                                    style={{
                                        gap: 4,
                                    }}
                                >
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Valor total inicial</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Valor total final</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Total inicial - total final</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Saving baseline</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Saving negociação</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Saving financeiro</Text>
                                    </View>
                                </View>
                                {suppliersTotals.map((supplierTotal) => {
                                    return (
                                        <View
                                            key={supplierTotal.supplierId}
                                            style={{
                                                gap: 4,
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.totalsLine,
                                                    styles.bgAccent,
                                                ]}
                                            >
                                                <Text>
                                                    {supplierTotal.supplier}
                                                </Text>
                                            </View>
                                            <View style={[styles.totalsLine]}>
                                                <Text>
                                                    {currencyFormat(
                                                        supplierTotal.totalInitial
                                                    )}
                                                </Text>
                                            </View>
                                            <View style={[styles.totalsLine]}>
                                                <Text>
                                                    {currencyFormat(
                                                        supplierTotal.totalFinal
                                                    )}
                                                </Text>
                                            </View>
                                            <View style={[styles.totalsLine]}>
                                                <Text>
                                                    {currencyFormat(
                                                        supplierTotal.totalDifference
                                                    )}
                                                </Text>
                                            </View>
                                            <View style={[styles.totalsLine]}>
                                                <Text>
                                                    {currencyFormat(
                                                        supplierTotal.baselineSaving
                                                    )}{' '}
                                                    (
                                                    {percentageFormat(
                                                        supplierTotal.baselineSavingPercentage *
                                                            100
                                                    )}
                                                    )
                                                </Text>
                                            </View>
                                            <View style={[styles.totalsLine]}>
                                                <Text>
                                                    {currencyFormat(
                                                        supplierTotal.negotiationSaving
                                                    )}{' '}
                                                    (
                                                    {percentageFormat(
                                                        supplierTotal.negotiationSavingPercentage *
                                                            100
                                                    )}
                                                    )
                                                </Text>
                                            </View>
                                            <View style={[styles.totalsLine]}>
                                                <Text>
                                                    {currencyFormat(
                                                        supplierTotal.financialSaving
                                                    )}{' '}
                                                    (
                                                    {percentageFormat(
                                                        supplierTotal.financialSavingPercentage *
                                                            100
                                                    )}
                                                    )
                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    gap: 4,
                                }}
                            >
                                <View style={{ gap: 4 }}>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Maior valor inicial</Text>
                                    </View>
                                    <View
                                        style={[
                                            styles.totalsLine,
                                            styles.bgAccent,
                                        ]}
                                    >
                                        <Text>Menor valor final</Text>
                                    </View>
                                </View>
                                <View style={{ gap: 4 }}>
                                    <View style={[styles.totalsLine]}>
                                        <Text>
                                            {currencyFormat(worstPrice)}
                                        </Text>
                                    </View>
                                    <View style={[styles.totalsLine]}>
                                        <Text>{currencyFormat(bestPrice)}</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default ApprovalPdf
