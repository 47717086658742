import { Table } from '@tanstack/react-table'
import { utils, writeFile } from 'xlsx'
import { PurchaseRequestTableType } from './components/PurchaseRequestTable'
import { PurchaseRequestFlat } from '@/api/business/solicitacaoDeCompra/type'

const HEADERS: Partial<
    Record<
        keyof PurchaseRequestFlat,
        {
            label: string
            order: number
        }
    >
> = {
    NK_SOLICITACAO_DE_COMPRAS: { label: 'Número da SC', order: 1 },
    NUMERO_DA_COTACAO: { label: 'Número da cotação', order: 2 },
    SK_EMPRESA: { label: 'Código empresa', order: 3 },
    ABREVIATURA_EMPRESA: { label: 'Empresa', order: 4 },
    NK_ITEM_SOLICITACAO_DE_COMPRAS: { label: 'Item SC', order: 5 },
    NK_PRODUTO: { label: 'Código produto', order: 6 },
    DS_PRODUTO: { label: 'Produto', order: 7 },
    DD_UM: { label: 'UM', order: 8 },
    DD_NCM: { label: 'NCM', order: 9 },
    DS_GRUPO_DE_PRODUTOS: { label: 'Grupo de produto', order: 10 },
    DD_URGENCIA: { label: 'Prioridade', order: 11 },
    DD_RATEIO: { label: 'Rateio', order: 12 },
    DD_STATUS: { label: 'Status', order: 13 },
    DS_NOME_COMPRADOR: { label: 'Comprador', order: 14 },
    NK_CENTRO_DE_CUSTO: { label: 'Código centro de custo', order: 15 },
    CENTRO_DE_CUSTO: { label: 'Centro de custo', order: 16 },
    NK_CONTA_ORCAMENTARIA: { label: 'Código conta orçamentária', order: 17 },
    CONTA_ORCAMENTARIA: { label: 'Conta orçamentária', order: 18 },
    QTD_SOLICITADA: { label: 'Qtd. solicitada', order: 19 },
    QTD_PEDIDA: { label: 'Qtd. pedida', order: 20 },
    ULTIMO_PRECO: { label: 'Último preço', order: 21 },
    DD_BLOQUEADO: { label: 'Bloqueado', order: 22 },
    DD_SOLICITANTE: { label: 'Solicitante', order: 23 },
    DD_OBSERVACAO: { label: 'Observação', order: 24 },
    DD_APROVADOR: { label: 'Aprovador', order: 25 },
    DT_PRAZO: { label: 'Data prazo', order: 26 },
    INTERVALO_PRAZO: { label: 'Intervalo prazo', order: 27 },
    ATRASO: { label: 'Atraso', order: 28 },
    DT_APROVACAO: { label: 'Data aprovação', order: 29 },
    DT_EMISSAO: { label: 'Data emissão', order: 30 },
    DT_NECESSIDADE: { label: 'Data necessidade', order: 31 },
} as const

export const usePurchaseRequestSpreadsheet = () => {
    const getTableRows = (
        table: Table<PurchaseRequestTableType>
    ): Partial<PurchaseRequestFlat>[] =>
        table.getRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            for (const [key, value] of Object.entries(row.original).sort(
                (a, b) => HEADERS[a[0]]?.order - HEADERS[b[0]]?.order
            )) {
                const header = HEADERS[key]?.label

                if (header && value) {
                    obj[header] = value.toString()
                }
            }

            return obj as PurchaseRequestFlat
        })

    const onDownload = (tableInstance: Table<PurchaseRequestTableType>) => {
        const rows = getTableRows(tableInstance)
        const worksheet = utils.json_to_sheet(rows)
        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Solicitações de compras')

        writeFile(workbook, 'solicitacoes-de-compras.xlsx', {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
