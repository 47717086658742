import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useBuyersPerformanceQuery } from '@/queries/usePurchaseRequestQuery'
import { MouseEvent, useMemo, useState } from 'react'
import getCssVariable from '../PriorityChart/util/getCssVariable'
import { Intervals, Priority } from '@/api/business/solicitacaoDeCompra/type'
import { Skeleton } from '@/components/ui/skeleton'
import { isAxiosError } from '@/api/business'
import { useDateIntervalContext } from '@/context/DateIntervalContextProvider'
import useDisclosure from '@/hooks/useDisclosure'
import BuyersDialog from './components/BuyersDialog'
import { Button } from '@/components/ui/button'
import { ExternalLink } from 'lucide-react'

export type BuyerInterval = {
    interval: Intervals
    total: number
}

export type BuyerPriority = {
    priority: Priority
    intervals: BuyerInterval[]
}

export type BuyerData = {
    name: string
    total: number
    priorities: BuyerPriority[]
}

export type UniqueBuyers = {
    name: string
    total: number
    priorities: Record<
        string,
        {
            priority: Priority
            intervals: Record<
                string,
                {
                    interval: Intervals
                    total: number
                }
            >
        }
    >
}

const BuyersPerformanceContent = () => {
    const { startDate, endDate } = useDateIntervalContext()
    const [selectedBuyer, setSelectedBuyer] = useState('')
    const [selectedBuyerPriority, setSelectedBuyerPriority] = useState<
        BuyerPriority[]
    >([])
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {
        data = [],
        isPending,
        error,
    } = useBuyersPerformanceQuery({ startDate, endDate })

    const onExpandClick = (
        e: MouseEvent,
        buyerName: string,
        buyerInterval: BuyerPriority[]
    ) => {
        e.stopPropagation()

        setSelectedBuyerPriority(buyerInterval)
        setSelectedBuyer(buyerName)
        onOpen()
    }

    const uniqueBuyers: BuyerData[] = useMemo(() => {
        const unique: Record<string, UniqueBuyers> = {}

        for (const row of data) {
            if (!unique[row.SK_COMPRADOR]) {
                unique[row.SK_COMPRADOR] = {
                    name: row.DS_NOME_COMPRADOR || '-',
                    total: 0,
                    priorities: {},
                }
            }

            if (!unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA]) {
                unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA] = {
                    priority: row.DD_URGENCIA,
                    intervals: {},
                }
            }

            if (
                !unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA].intervals[
                    row.INTERVALO_PRAZO
                ]
            ) {
                unique[row.SK_COMPRADOR].total += row.TOTAL
                unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA].intervals[
                    row.INTERVALO_PRAZO
                ] = {
                    interval: row.INTERVALO_PRAZO,
                    total: 0,
                }
            }

            unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA].intervals[
                row.INTERVALO_PRAZO
            ] = {
                ...unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA]
                    .intervals[row.INTERVALO_PRAZO],
                total:
                    unique[row.SK_COMPRADOR].priorities[row.DD_URGENCIA]
                        .intervals[row.INTERVALO_PRAZO].total + row.TOTAL,
            }
        }

        return Object.values(unique)
            .map((buyer) => ({
                ...buyer,
                priorities: Object.values(buyer.priorities).map((priority) => ({
                    ...priority,
                    intervals: Object.values(priority.intervals),
                })),
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [data])

    if (isPending) {
        return (
            <div className="space-y-1">
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
            </div>
        )
    }

    if (error) {
        let errorMessage = 'Erro ao listar compradores'

        if (isAxiosError(error)) {
            errorMessage = error.response?.data.message || errorMessage
        }

        return (
            <div>
                <TypographyP className="text-destructive">
                    {errorMessage}
                </TypographyP>
            </div>
        )
    }

    if (data.length === 0)
        return (
            <div>
                <TypographyMuted>Não há dados disponíveis</TypographyMuted>
            </div>
        )

    return (
        <>
            <div className="space-y-4 max-h-[400px] overflow-auto">
                <Accordion type="single" collapsible>
                    {uniqueBuyers.map((buyer) => (
                        <AccordionItem key={buyer.name} value={buyer.name}>
                            <AccordionTrigger className="font-normal">
                                <div className="flex items-center gap-2">
                                    <Button
                                        size="icon"
                                        variant="ghost"
                                        onClick={(e) =>
                                            onExpandClick(
                                                e,
                                                buyer.name,
                                                buyer.priorities
                                            )
                                        }
                                    >
                                        <ExternalLink size={14} />
                                    </Button>
                                    <TypographyP>
                                        <span className="font-semibold">
                                            {buyer.name}
                                        </span>{' '}
                                        ({buyer.total} solicitações)
                                    </TypographyP>
                                </div>
                            </AccordionTrigger>
                            <AccordionContent className="p-4 rounded-lg bg-neutral-50 dark:bg-card">
                                <div className="grid grid-cols-[repeat(auto-fill,minmax(max-content,200px))] gap-4">
                                    {buyer.priorities.map((priority) => (
                                        <div key={priority.priority}>
                                            <TypographyP
                                                style={{
                                                    color: `hsl(var(${getCssVariable(
                                                        priority.priority
                                                    )}))`,
                                                }}
                                                className="font-bold"
                                            >
                                                {priority.priority}
                                            </TypographyP>
                                            <div className="space-y-1">
                                                {priority.intervals.map(
                                                    (interval) => (
                                                        <div
                                                            key={
                                                                interval.interval
                                                            }
                                                        >
                                                            <TypographyP className="text-xs truncate">
                                                                {
                                                                    interval.interval
                                                                }{' '}
                                                                -{' '}
                                                                <span className="font-semibold">
                                                                    {
                                                                        interval.total
                                                                    }
                                                                </span>
                                                            </TypographyP>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
            <BuyersDialog
                buyerName={selectedBuyer}
                buyerPriorities={selectedBuyerPriority}
                isOpen={isOpen}
                onClose={onClose}
            />
        </>
    )
}

const BuyersPerformanceSection = () => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Solicitações por comprador</CardTitle>
                <CardDescription>
                    Listagem de SCs por comprador separadas por prioridade
                </CardDescription>
            </CardHeader>
            <CardContent>
                <BuyersPerformanceContent />
            </CardContent>
        </Card>
    )
}

export default BuyersPerformanceSection
