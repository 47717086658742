import { useState } from 'react'
import useDisclosure from './useDisclosure'
import {
    ColumnFiltersState,
    ColumnOrderState,
    ExpandedState,
    PaginationState,
    Row,
    RowSelectionState,
    Table,
    TableState,
} from '@tanstack/react-table'

export const useTable = <T>() => {
    const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
    const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([])
    const [expandedState, setExpandedState] = useState<ExpandedState>({})
    const [selectedRow, setSelectedRow] = useState<Row<T> | null>(null)
    const [tableState, setTableState] = useState<TableState>()

    const [columnFilters, onColumnFiltersChange] = useState<ColumnFiltersState>(
        []
    )
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 25,
    })

    const onPaginationChange = ({ pageIndex, pageSize }: PaginationState) => {
        setPagination((prev) => ({ ...prev, pageSize, pageIndex }))
    }

    const [table, setTable] = useState<Table<T>>()

    const {
        isOpen: isEditDialogOpen,
        onClose: onEditDialogClose,
        onOpen: onEditDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isDeleteDialogOpen,
        onClose: onDeleteDialogClose,
        onOpen: onDeleteDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isAddDialogOpen,
        onClose: onAddDialogClose,
        onOpen: onAddDialogOpen,
    } = useDisclosure()

    const {
        isOpen: isBulkDialogOpen,
        onClose: onBulkDialogClose,
        onOpen: onBulkDialogOpen,
    } = useDisclosure()

    const onClickEdit = (row: Row<T>) => {
        setSelectedRow(row)
        onEditDialogOpen()
    }

    const onClickDelete = (row: Row<T>) => {
        setSelectedRow(row)
        onDeleteDialogOpen()
    }

    const onClickMultiDelete = () => {
        setSelectedRow(null)
        onDeleteDialogOpen()
    }

    const onClickAdd = () => {
        onAddDialogOpen()
    }

    const onColumnOrderChange = (columnOrderState: ColumnOrderState) => {
        setColumnOrder(columnOrderState)
    }

    const onTableStateChange = (newState: TableState) => {
        setTableState(newState)
    }

    return {
        table,
        tableState,
        expandedState,
        selectedRows,
        selectedRow,
        isAddDialogOpen,
        isEditDialogOpen,
        isDeleteDialogOpen,
        isBulkDialogOpen,
        columnOrder,
        columnFilters,
        pageIndex,
        pageSize,
        onPaginationChange,
        onColumnFiltersChange,
        onColumnOrderChange,
        setTable,
        onTableStateChange,
        setExpandedState,
        setSelectedRow,
        setSelectedRows,
        onAddDialogOpen,
        onAddDialogClose,
        onEditDialogOpen,
        onEditDialogClose,
        onDeleteDialogClose,
        onDeleteDialogOpen,
        onClickAdd,
        onClickDelete,
        onClickEdit,
        onClickMultiDelete,
        onBulkDialogOpen,
        onBulkDialogClose,
    }
}
